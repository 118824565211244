import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import { WindowLocation } from '@reach/router'

import Loading from '../components/Loading'

import useAuth from '../util/useAuth'
import * as api from '../util/api'

type ResetProps = { location: WindowLocation }

const ResetPassword: React.FC<ResetProps> = ({ location }) => {
  const { code } = queryString.parse(location.search)
  const { getUser } = useAuth()

  useEffect(() => {
    if (!code) navigate('/')
    else
      getUser()
        .then(async (payload) => {
          await api.wrikeConnect(payload.jwt, code.toString())
          navigate('/profile')
        })
        .catch(() => navigate('/auth'))
  }, [])

  return <Loading isAuth />
}
export default ResetPassword
